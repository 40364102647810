var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-img',{attrs:{"max-width":"250","src":require("../../../assets/just-scrum.png")}})],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"10","lg":"8","xl":"6"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [(_vm.show)?_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('auth.register')))])],1),(_vm.formError)?_c('v-alert',{attrs:{"border":"left","type":"error","text":""}},[_vm._v(" "+_vm._s(_vm.formError)+" ")]):_vm._e(),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('auth.registerForm.username'),"rules":"required|email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autofocus":"","data-cy":"register-email","label":_vm.$t('auth.registerForm.username'),"error-messages":errors[0],"filled":"","prepend-inner-icon":"mdi-account","type":"email"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"vid":"confirm","name":_vm.$t('auth.registerForm.password'),"rules":"min:8|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"data-cy":"register-password","label":_vm.$t('auth.registerForm.password'),"error-messages":errors[0],"filled":"","prepend-inner-icon":"mdi-lock","type":"password"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('auth.registerForm.repeatPassword'),"rules":"required|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"data-cy":"register-repeatPassword","label":_vm.$t('auth.registerForm.repeatPassword'),"error-messages":errors[0],"filled":"","prepend-inner-icon":"mdi-lock","type":"password"},model:{value:(_vm.formData.repeatPassword),callback:function ($$v) {_vm.$set(_vm.formData, "repeatPassword", $$v)},expression:"formData.repeatPassword"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('auth.registerForm.firstname'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"data-cy":"register-firstname","label":_vm.$t('auth.registerForm.firstname'),"error-messages":errors[0],"filled":""},model:{value:(_vm.formData.firstname),callback:function ($$v) {_vm.$set(_vm.formData, "firstname", $$v)},expression:"formData.firstname"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('auth.registerForm.surname'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"data-cy":"register-surname","label":_vm.$t('auth.registerForm.surname'),"error-messages":errors[0],"filled":""},model:{value:(_vm.formData.surname),callback:function ($$v) {_vm.$set(_vm.formData, "surname", $$v)},expression:"formData.surname"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"flex-grow-1 flex-sm-grow-0",attrs:{"loading":_vm.isLoading,"data-cy":"register-submit","type":"submit","color":"primary","disabled":invalid}},[_vm._v(_vm._s(_vm.$t('auth.registerForm.submit')))])],1)],1)],1):_vm._e()]}}])})],1)],1),_c('v-row',{staticClass:"my-3",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"to":{ name: 'Login' }}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" "+_vm._s(_vm.$t('auth.registerForm.backToLogin'))+" ")],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"to":{ name: 'Imprint' }}},[_vm._v(_vm._s(_vm.$t('imprint.title')))]),_c('v-btn',{attrs:{"to":{ name: 'Privacy' }}},[_vm._v(_vm._s(_vm.$t('privacy.title')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }