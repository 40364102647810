








































































































































import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import { ValidationObserver } from 'vee-validate'
import { RegisterData } from '@/models/interface/RegisterData'

@Component
export default class Register extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>
  }

  show = true
  formError = ''
  formData: RegisterData = {
    email: '',
    password: '',
    repeatPassword: '',
    firstname: '',
    surname: '',
    acceptedTerms: false
  }
  isLoading = false

  resetForm() {
    this.formError = ''
    this.formData.email = ''
    this.formData.password = ''
    this.formData.repeatPassword = ''
    this.formData.firstname = ''
    this.formData.surname = ''

    this.show = false
    this.$nextTick(() => {
      this.show = true
    })
  }
  submitForm() {
    this.register(this.formData)
  }
  async register(data: RegisterData) {
    try {
      this.isLoading = true
      this.formError = ''
      await this.$store.dispatch('auth/register', data)
      await router.push({ name: 'Login' })
    } catch (error) {
      this.formError = error.response.data['hydra:title']
    } finally {
      this.isLoading = false
    }
  }
}
